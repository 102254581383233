$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

$container-max-widths: (
  sm: 1280px
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);
$body-bg: #fff;
$body-color:#494949;
$line-height-base: 1.3;
$primary: #CC0000;
$secondary: #1C1C1D;
$light:#f3f3f3;
$gray:#f5f5f5;
$white: #fff;
$dark: #000;
$red: #CC0000;
$font-family-sans-serif: 'Circular Pro', sans-serif;
$font-family-base: 'Circular Pro', sans-serif;
$font-family-arabic: 'Cairo', sans-serif;;


$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;


$gray-100: #F3F3F3;
$gray-200: #f7f7f7;
$gray-300: #FAFAFA;
$gray-400: #f5f5f5;
$gray-500: #C4C4C4;
$gray-600: #A5A5A5;
$gray-700: #818181;
$gray-800: #494949;
$gray-900: #2E2D2C;



$border-color: #e7e8e9;
$link-color:#494949;
$link-hover-color: $primary;

$h1-font-size: rem(42);
$h2-font-size: rem(35);
$h3-font-size: rem(30);
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 20px;

$display1-size: rem(55);
// $display2-size: rem(60);
// $display3-size: rem(40);
// $display4-size: rem(35);
$headings-color:#1C1C1D;
$headings-font-weight: $font-weight-bold;

$border-radius: 2px;
$border-radius-sm: 2px;
$border-radius-lg: 2px;

$input-border-radius: 2px;
$input-border-color: #DFDFDF;
$input-focus-border-color:#494949;
$input-font-size: 14px;
$input-padding-x : 0.875rem;
$input-btn-padding-y: 0.875rem;
$input-btn-font-size: 15px;
$input-btn-focus-color: $border-color;
$input-btn-focus-width: 0;
$input-color: $gray-900;
$input-placeholder-color: $gray-700;
$input-btn-font-size-sm: 14px;
$input-btn-padding-y-sm: 0.625rem;
$input-btn-padding-x-sm: 1.5rem;
$input-padding-x:1rem;
$input-padding-x-sm:1rem;
$input-font-size-sm:15px;
$input-btn-font-size-lg:15px;
$btn-padding-x: 2.5rem;
$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$btn-font-weight: $font-weight-bold;

$dropdown-border-radius: 0;
$zindex-dropdown: 10000;
$enable-shadows: false;


$link-decoration: none;
// $link-hover-color: darken($link-color, 20%);
$link-hover-decoration: none;
$text-muted: $gray-700;

$footer-color: $white;
$footer-bg: $secondary;
$footer-hover-color: $primary;

$modal-md : 640px;